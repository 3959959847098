export default class AdditionalField {
  cols = 12
  sm = 6
  md = 4
  lg = null
  xl = null
  type = 'select'
  items = []
  label = null
  required = false
  hint = null
  perPlayer = false
  divisions = ['*']
  multiple = false

  constructor (dto) {
    this.update(dto)
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    Object.assign(this, dto)
  }

  get valid () {
    const itemsValid = (this.type !== 'select' || this.items.length > 1)

    return !!(itemsValid && this.type && this.label)
  }

  get dto () {
    const a = {
      cols: this.cols,
      sm: this.sm,
      md: this.md,
      lg: this.lg,
      xl: this.xl,
      type: this.type,
      items: this.items,
      label: this.label,
      hint: this.hint,
      divisions: this.divisions,
      required: !!this.required,
      perPlayer: !!this.perPlayer,
      multiple: !!this.multiple
    }
    for (const p in a) {
      if (a[p] === null) {
        delete a[p]
      }
    }
    return a
  }
}
