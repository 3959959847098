<template>
  <v-col
    v-if="iField"
    :cols="iField.cols"
    :sm="iField.sm"
    :md="iField.md"
    :lg="iField.lg"
  >
  <v-select v-if="iField.type === 'select'"
      :items="iField.items"
      :label="`${iField.label}${iField.required ? '*' : ''}`"
      v-model="v"
      :hint="iField.hint"
      persistent-hint
      color="color3"
      item-color="color3"
      :solo="solo"
      @blur="onBlur"
      :error-messages="touched ? errors : []"
      :clearable="!iField.required"
      >
    </v-select>
    <v-text-field v-else-if="iField.type === 'textbox'"
      :label="`${iField.label}${iField.required ? '*' : ''}`"
      v-model="v"
      :hint="iField.hint"
      persistent-hint
      color="color3"
      item-color="color3"
      :solo="solo"
      @blur="onBlur"
      :error-messages="touched ? errors : []"
      >
    </v-text-field>
    <v-textarea v-else-if="iField.type === 'textarea'"
      :label="`${iField.label}${iField.required ? '*' : ''}`"
      v-model="v"
      :hint="iField.hint"
      persistent-hint
      color="color3"
      item-color="color3"
      :solo="solo"
      @blur="onBlur"
      :error-messages="touched ? errors : []"
      :rows="3"
      :auto-grow="true"
      >
    </v-textarea>
  </v-col>
</template>

<script>
import AdditionalField from '@/classes/AdditionalField'

export default {
  props: ['field', 'solo', 'registration', 'fieldI', 'player', 'playerI'],
  data () {
    return {
      iField: null,
      touched: false,
      v: null
    }
  },
  computed: {
    errors () {
      return this.iField && this.iField.required && !this.v ? [(this.iField.requiredError || `${this.iField.label} is required`)] : []
    },
    fieldKey () {
      return this.iField ? this.iField.perPlayer ? `${this.registration.division.personType} ${this.playerI} ${this.iField.label}` : this.iField.label : ''
    }
  },
  methods: {
    getValue () {
      this.v = this.registration.getAddField(this.fieldKey)
    },
    setValid () {
      const v = {
        i: this.fieldI,
        valid: this.errors.length === 0
      }
      this.$emit('valid-change', v)
    },
    onBlur () {
      this.touched = true
      this.registration.setAddField(this.fieldKey, this.v)
    }
  },
  watch: {
    'errors.length': 'setValid'
  },
  mounted () {
    this.iField = new AdditionalField(this.field)
    this.getValue()
    this.setValid()
  }
}
</script>
